import './App.css';
import { useState, useEffect } from 'react';

interface Item {
  id: number;
  name: string;
  price: number;
  categoryId: number; 
  imageUrl: string; 
}

interface Category {
  id: number;
  name: string;
}

const categories: Category[] = [
  { id: 1, name: 'Dogos' },
  { id: 2, name: 'Papas' },
  { id: 3, name: 'Ensaladas' },
  { id: 4, name: 'Bebidas' },
];

const items: Item[] = [
  {
    id: 1,
    name: 'Hotdog',
    price: 2.5,
    categoryId: 1,
    imageUrl: 'https://st3.depositphotos.com/1000260/15010/i/450/depositphotos_150104292-stock-photo-fat-man-eating-fast-food.jpg'
  },
  {
    id: 2,
    name: 'Papas Fritas',
    price: 2.0,
    categoryId: 2,
    imageUrl: 'https://st3.depositphotos.com/1000260/15010/i/600/depositphotos_150104292-stock-photo-fat-man-eating-fast-food.jpg'
  },
  {
    id: 3,
    name: 'Ensalada César',
    price: 4.0,
    categoryId: 3,
    imageUrl: 'https://st3.depositphotos.com/1000260/15010/i/600/depositphotos_150104292-stock-photo-fat-man-eating-fast-food.jpg'
  },
  {
    id: 4,
    name: 'Coca-Cola',
    price: 1.5,
    categoryId: 4,
    imageUrl: 'https://st3.depositphotos.com/1000260/15010/i/600/depositphotos_150104292-stock-photo-fat-man-eating-fast-food.jpg'
  },
  {
    id: 5,
    name: 'Agua',
    price: 1.0,
    categoryId: 4,
    imageUrl: 'https://st3.depositphotos.com/1000260/15010/i/600/depositphotos_150104292-stock-photo-fat-man-eating-fast-food.jpg'
  },
];

export default function App() {
  const [activeTab, setActiveTab] = useState('catalog');
  const [activeCategories, setActiveCategories] = useState<number[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredItems, setFilteredItems] = useState(items);
  const [cart, setCart] = useState<{ id: number; name: string; price: number; quantity: number }[]>([]);

  useEffect(() => {

    let filtered;
    if (searchTerm) {
      filtered = items.filter(item =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        categories.find(category => category.id === item.categoryId)?.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setActiveCategories([]); 
    } else if (activeCategories.length > 0) {
      filtered = items.filter(item => activeCategories.includes(item.categoryId));
    } else {
      filtered = items;
    }
    setFilteredItems(filtered);
  }, [searchTerm, activeCategories]);

  const handleCategoryClick = (categoryId: number) => {
    setSearchTerm(''); 
    setActiveCategories(prev => 
      prev.includes(categoryId)
        ? prev.filter(id => id !== categoryId)
        : [...prev, categoryId] 
    );
  };

  const addToCart = (item: { id: number; name: string; price: number }) => {
    setCart(prevCart => {
      const existingItem = prevCart.find(cartItem => cartItem.id === item.id);
      if (existingItem) {
        return prevCart.map(cartItem =>
          cartItem.id === item.id ? { ...cartItem, quantity: cartItem.quantity + 1 } : cartItem
        );
      } else {
        return [...prevCart, { ...item, quantity: 1 }];
      }
    });
    setActiveTab('sales');
  };

  const updateQuantity = (id: number, change: number) => {
    setCart(prevCart =>
      prevCart
        .map(item =>
          item.id === id
            ? { ...item, quantity: Math.max(0, item.quantity + change) }
            : item
        )
        .filter(item => item.quantity > 0)
    );
  };

  const totalAmount = cart.reduce((sum, item) => sum + item.price * item.quantity, 0);

  const renderContent = () => {
    switch (activeTab) {
      case 'catalog':
        return (
          <div className="p-4">
            <div className="relative mb-4">
              <input
                type="text"
                placeholder="Buscar"
                className="w-full p-2 pl-8 bg-gray-200 rounded-full"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 absolute left-2 top-2.5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-2 mb-4">
              {categories.map((category) => {
                const isHighlighted = activeCategories.includes(category.id);

                return (
                  <button
                    key={category.id}
                    className={`p-2 rounded-lg text-center text-sm ${isHighlighted ? 'bg-blue-200' : 'bg-gray-200'}`}
                    onClick={() => handleCategoryClick(category.id)}
                  >
                    {category.name}
                  </button>
                );
              })}
            </div>
            <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-4">
              {filteredItems.map((item) => (
                <div
                  key={item.id}
                  className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer"
                  onClick={() => addToCart(item)}
                >
                  <img
                    src={item.imageUrl}
                    alt={item.name}
                    className="w-full h-32 object-cover"
                  />
                  <div className="p-2">
                    <h3 className="font-bold">{item.name}</h3>
                    <p className="text-gray-600">${item.price.toFixed(2)}</p>
                    <p className="text-sm text-gray-500">{categories.find(category => category.id === item.categoryId)?.name}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      case 'sales':
        return (
          <div className="p-4">
            <div className="space-y-4">
              {cart.map((item:any) => (
                <div key={item.id} className="flex justify-between items-center bg-white rounded-lg shadow-md p-2">
                  <div className="flex items-center">
                    <img
                      src={item.imageUrl}
                      alt={item.name}
                      className="w-12 h-12 object-cover rounded-md mr-2"
                    />
                    <div>
                      <h3 className="font-bold">{item.name}</h3>
                      <p className="text-gray-600">${item.price.toFixed(2)}</p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2 bg-gray-100 rounded-full p-1">
                    <button
                      className="w-6 h-6 bg-white rounded-full text-gray-600"
                      onClick={() => updateQuantity(item.id, -1)}
                    >
                      -
                    </button>
                    <span className="w-6 text-center">{item.quantity}</span>
                    <button
                      className="w-6 h-6 bg-white rounded-full text-gray-600"
                      onClick={() => updateQuantity(item.id, 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
              ))}
            </div>
            {cart.length > 0 ? (
              <button className="w-full mt-4 p-2 bg-blue-500 text-white rounded-lg font-bold">
                Cobrar ${totalAmount.toFixed(2)}
              </button>
            ) : (
              <p className="text-center mt-4 text-gray-500">El carrito está vacío</p>
            )}
          </div>
        );
      case 'settings':
        return (
          <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">Ajustes</h2>
            <p>El contenido de ajustes irá aquí.</p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="flex border-b justify-around">
        {['catalog', 'sales', 'settings'].map((tab) => (
          <button
            key={tab}
            className={`flex-1 py-2 px-4 text-center ${activeTab === tab ? 'text-blue-500 border-b-2 border-blue-500 font-medium' : 'text-gray-500'}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab === 'catalog' && 'Catálogo'}
            {tab === 'sales' && 'Venta'}
            {tab === 'settings' && 'Ajustes'}
          </button>
        ))}
      </div>
      <div className="container mx-auto px-4">
        {renderContent()}
      </div>
    </div>
  );
}
